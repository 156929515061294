import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h2>Making better choices</h2>
    <p>Welcome to Glance. </p>
      
    <p>Glance is a ratings and recommendations guide for retail products. Our goal is to help you have the information you need to make better purchase decisions.</p>
    
    <p> Coming Soon.</p>
    
    <div style={{ marginBottom: `1.45rem` }}>
      <Image />
      <span class='tiny'>Image source: <a href='https://www.pexels.com/photo/black-and-white-bridge-leaf-outdoors-276204' target="_blank">pexels</a></span>
    </div>  
    
    
  </Layout>

  
)

export default IndexPage
